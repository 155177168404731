<template>
  <section id="hero" class="flex items-center justify-center">
    <div class="container flex flex-row gap-32">
      <div class="text flex flex-column justify-center gap-12 ma-to width-40">
        <h1>Full-Stack<br> Developer<span>.</span></h1>
        <p>Hi, I'm Marlon Schirl, a passionate Full-Stack Developer based in Nuremberg, Germany. With a strong foundation in both front-end and back-end technologies, I specialize in creating seamless, user-friendly web applications.</p>

        <ul>
          <li>
            <a aria-label="github" rel="noreferrer" target="_blank" href="https://github.com/cptprice1139">
              <img src="@/assets/images/icons/y/github.svg" alt="Github" />
            </a>
          </li>
          <li>
            <a aria-label="linkedin" rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/marlon-schirl-81a046296/">
              <img src="@/assets/images/icons/y/linkedin.svg" alt="LinkedIn" /></a>
          </li>
          <li>
            <a aria-label="instagram" rel="noreferrer" target="_blank" href="https://www.instagram.com/marloni._/">
              <img src="@/assets/images/icons/y/instagram.svg" alt="Instagram" />
            </a>
          </li>
        </ul>
      </div>

      <div class="image flex flex-column items-end grow-1 ma-to">
        <figure>
          <img class="mobile" src="@/assets/images/figures/figure_b.svg" alt="">

          <img src="@/assets/images/figures/figure_a.svg" alt="">

          <span class="image"></span>
        </figure>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
#hero {
  min-height: 80vh;
  background: linear-gradient(90deg, #4831d4 67%, #ccf381 33%);

  @media (max-width: #{$media-medium}) {
    background: linear-gradient(180deg, #4831d4 55%, #ccf381 45%);
    min-height: auto !important;
    padding: 6rem 0;

    .text {
      width: 100%;
    }

    .image {
      width: 100%;
      justify-content: center;
      align-items: center;

      figure {
        margin: 4rem 0 0 0;
      }
    }
  }

  h1 {
    color: $yellow;
  }

  .ma-to {
    margin-top: 5rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 8px;

    li {

      a {
        position: relative;
        font-weight: 400;
        color: $yellow;
        text-decoration: none;
        margin: 0;
        padding: 0;

        img {
          width: 2.5rem;
          height: 2.5rem;
          transition: .2s ease-in-out;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  figure {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22rem;
    height: 22rem;
    margin: 2rem 25% 0 2rem;

    @media (max-width: #{$media-medium}) {
      width: 20rem;
      height: 20rem;
    }

    &::after {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      border: 2px solid #fff;
      content: '';
      top: 0;
      left: 0;
    }

    img {
      width: 180%;
      height: 160%;
      right: -25%;
      top: -35%;
      position: absolute;

      @media (max-width: #{$media-medium}) {
          display: none;
        }

      &.mobile {
        display: none;

        @media (max-width: #{$media-medium}) {
          display: block !important;
        }
      }
    }

    span {
      z-index: 3;
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      right: 1rem;
      bottom: 1rem;
      background-image: url('@/assets/images/pfp.webp');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      margin: 0 .655rem .655rem 0;
    }
  }
}
</style>