<template>
  <Navbar :name-expanded="true" v-if="example" />

  <section v-if="example">
    <div class="preview-image" v-if="preview">
      <span v-on:click="preview = false"></span>
      <img :src="previewImage" alt="preview">
    </div>

    <section id="my-work" class="flex items-center justify-center">
      <div class="container flex justify-between">
        <div class="work-preview flex flex-column justify-center items-center gap-32 full-width">
          <h2>{{ example.short_label }}</h2>

          <swiper
              :loop="true"
              :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
              }"
              :pagination="{
                clickable: true,
              }"
              :modules="modules"
          >
            <swiper-slide v-for="(image, i) in example.images" :key="i" :virtualIndex="i" @click="openPreview(example.name, image)">
              <img :src="`https://dashboard.marlon-schirl.me/api/v1/images/${example.name}/${image}`" alt="preview">
            </swiper-slide>
          </swiper>

          <p v-html="example.description"></p>
        </div>
      </div>
    </section>
  </section>

  <hr v-if="example">

  <Contact v-if="example" />

  <Footer v-if="example" />

  <div class="loading" v-if="example === null">
    <DotLottieVue class="lottie" autoplay loop src="https://lottie.host/c32dfdfe-a1b0-45c2-b9f7-152c8f831740/58dAwWgbo0.json" />
  </div>
</template>

<script>
import { DotLottieVue } from '@lottiefiles/dotlottie-vue';
import Navbar from '@/components/Navbar.vue';

import Footer from '@/components/sections/Footer.vue';
import axios from 'axios';

import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Pagination } from 'swiper/modules';
import Contact from '@/components/sections/Contact.vue';
import { useMeta } from 'vue-meta';

export default {
  components: {Contact, Footer, Navbar, DotLottieVue, Swiper, SwiperSlide },
  data() {
    return {
      example: null,
      preview: false,
      previewImage: ''
    }
  },
  methods: {
    openPreview(name, slide) {
      this.preview = true;

      this.previewImage = `https://dashboard.marlon-schirl.me/api/v1/images/${name}/${slide}`;
    }
  },
  async mounted() {
    const rootStyle = document.querySelector(':root').style;

    rootStyle.setProperty('--currentColor', '#4831D4FF');
    rootStyle.setProperty('--logoColor', '#4831D4FF');

    document.querySelector('body').style.overflowY = 'auto';

    let res = await axios.get(`https://dashboard.marlon-schirl.me/api/v1/data/${window.location.href.split('/')[4]}`);

    this.example = res.data;
  },
  setup() {
    useMeta({
      link: [
        { rel: 'canonical', href: `https://marlon-schirl.me/my-work/${window.location.href.split('/')[4]}` }
      ]
    });
    return {
      modules: [Autoplay, Pagination],
    }
  }
}
</script>

<style lang="scss" scoped>
nav {
  position: absolute;
}

#footer {
  min-height: 70vh !important;
  max-height: 70vh !important;
}

#contact {
  min-height: 80vh !important;
  max-height: 80vh !important;
}

hr {
  width: 60%;
  background: rgba(#474747, .15);
  border-color: transparent;
}

.work-preview {
  margin: 14rem 0 10rem 0;

  @media (max-width: #{$media-medium}) {
    margin: 0;
  }

  .swiper {
    width: 100%;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    :global(.swiper-pagination-bullet-active) {
      background-color: $purple !important;
    }
  }
}

.preview-image {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: $grey;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    position: fixed;
    top: 2vw;
    right: 5vw;
    width: 2.225rem;
    height: 2.225rem;
    cursor: pointer;

    &::before, &::after {
      content: '';
      height: 2px;
      right: 0;
      width: 100%;
      position: absolute;
      z-index: 9999;
      bottom: 50%;
      transform: rotate(0deg);
      background-color: $purple;
      transition: .2s ease-in-out;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  img {
    width: 75%;
  }
}

h2 {
  font-size: 1.75rem;
  color: #474747;
  margin: 0;
}

p {
  color: #474747;
  text-align: center;
  width: 70%;

  @media (max-width: #{$media-medium}) {
    width: 100%;
  }
}

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .lottie {
    width: 25rem;
    height: 25rem;
    transform: rotate(45deg);
  }
}
</style>