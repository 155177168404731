import { createRouter, createWebHistory } from 'vue-router';

import Homepage from '@/views/Homepage.vue';
import MyWork from '@/views/MyWork.vue';
import WorkPreview from '@/views/WorkPreview.vue';

const routes = [
  {
    path: '/',
    component: Homepage
  },
  {
    path: '/my-work',
    component: MyWork,
  },
  {
    path: '/my-work/:name',
    component: WorkPreview,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router;
