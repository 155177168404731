<template>
  <Navbar :name-expanded="true" />

  <section id="my-work" class="flex justify-center">
    <div class="container flex gap-32 full-width">
      <div class="work flex flex-column items-center gap-12 full-width">
        <h3><span>/</span>my-work<span>.</span></h3>
        <p>Work I've taken on in the past.</p>

        <div class="work-container">
          <a class="work-example" v-for="(example, i) in examples" :key="i" :href="`/my-work/${example.name}`">
            <div class="preview">
              <img :src="`https://dashboard.marlon-schirl.me/api/v1/images/${example.name}/${example.logo}`" alt="preview">
            </div>
            <div class="label">
              <p>{{ example.short_label }}</p>
              <a rel="noreferrer" target="_blank" :href="example.link">
                {{ example.link || '&nbsp;' }}
              </a>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>

  <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/sections/Footer.vue';
import axios from 'axios';
import {useMeta} from 'vue-meta';

export default {
  components: { Footer, Navbar },
  data() {
    return {
      examples: null
    }
  },
  async mounted() {
    const rootStyle = document.querySelector(':root').style;

    rootStyle.setProperty('--currentColor', '#4831D4FF');
    rootStyle.setProperty('--logoColor', '#4831D4FF');

    document.querySelector('body').style.overflowY = 'auto';

    let res = await axios.get('https://dashboard.marlon-schirl.me/api/v1/data');

    this.examples = res.data;
  },
  setup() {
    useMeta({
      link: [
        { rel: 'canonical', href: 'https://marlon-schirl.me/my-work' }
      ]
    });
  }
}
</script>

<style scoped lang="scss">
nav {
  position: absolute;
}

#my-work {
  background: #F5F4FC;

  .work {
    margin: 14rem 0;

    @media (max-width: #{$media-medium}) {
      margin: 6rem 0;
    }

    h3 {
      font-size: 1.75rem;
      color: #474747;
      margin: 0;

      span {
        opacity: .75;
      }
    }

    p {
      color: #474747;
    }

    .work-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 90%;
      gap: 2rem;
      margin-top: 4rem;
      padding: 0 6rem;

      @media (max-width: #{$media-small}) {
        grid-template-columns: 1fr;
      }

      @media (max-width: 1024px) {
        padding: 0 0 !important;
      }

      .work-example {
        width: 100%;
        background: $grey;
        min-height: 16rem;
        box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.01);
        transition: .2s ease-in-out;
        text-decoration: none;

        &:hover {
          cursor: pointer;
          transform: translateY(-5px);
        }

        .preview {
          height: 70%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #F9F8FD;

          img {
            width: 30%;

            @media (max-width: #{$media-small}) {
              width: 20%;
            }
          }
        }

        .label {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 0;
          padding: 0 2rem;
          height: 40%;
          background: $white;

          p {
            color: #474747;
            font-weight: 600;
          }

          a {
            color: $purple;
            font-size: 1rem;
            text-decoration: none;
          }
        }
      }
    }
  }
}

#footer {
  min-height: 70vh !important;
  max-height: 70vh !important;
}
</style>