<template>
  <section id="how-it-works" class="flex items-center justify-center">
    <div class="container flex flex-row justify-between gap-32">
      <div class="conception flex flex-column justify-center gap-12 width-40">
        <h2>Conception</h2>
        <p class="animate__animated">I work closely with clients to capture their vision, bringing ideas to life with intuitive, user-friendly designs. With a focus on scalable, maintainable, and efficient code, I ensure that every app performs flawlessly.</p>

        <img src="@/assets/images/figures/figure_c.svg" alt="">
      </div>

      <div class="engineering flex flex-column width-40">
        <img src="@/assets/images/figures/figure_d.svg" alt="">

        <h2>Engineering</h2>
        <p>As an innovative web app developer, I engineer applications that are powerful, secure, and maintainable. Utilizing a variety of technologies—from front-end frameworks like React and Angular to back-end solutions like Node.js and Django—I select the best tools to meet each project's unique needs. My strategic approach, involving thorough planning, agile development, and continuous testing, ensures your app excels in a competitive digital landscape. Let’s create a standout digital solution that drives success.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
#how-it-works {

  h2 {
    color: $purple;
  }

  p {
    color: $dark-purple;
  }

  @media (max-width: #{$media-medium}) {
    .conception, .engineering {
      width: 100%;
    }

    .conception img, .engineering img {
      display: none;
    }
  }

  .conception {

    img {
      width: 12vw;
      margin: 4vw 10vw;
    }
  }

  .engineering {

    img {
      width: 18vw;
      margin: 2vw 2vw 0 8vw;
    }
  }
}
</style>