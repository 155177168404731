<template>
  <section id="about" class="flex items-center justify-center">
    <div class="container flex flex-row justify-between gap-32">
      <div class="text flex flex-column justify-center gap-12 width-50">
        <h2>Self-taught,</h2>
        <p>Yes, you heard right! I've been teaching myself how to code for almost six years now. My journey hasn't always been focused on web development; I've explored many different fields along the way.
          <br><br>
          For a long time, I delved into game development, using game engines and even grappling with the complexities of C++ and OpenGL. This experience, while challenging, taught me diverse problem-solving skills and gave me unique perspectives that I can now apply to my current projects.
          <br><br>
          Today, my primary focus is on creating scalable, efficient, and maintainable web apps for a variety of ideas. Alongside my studies, I've been freelancing in this field for about a year and a half, and I'm currently working to expand this into a thriving business.
        </p>
      </div>

      <div class="img flex flex-column justify-center">
        <DotLottieVue class="lottie" autoplay loop src="https://lottie.host/5cbcb5e8-779b-4181-8130-be58a9f8d049/mtUGbVlUrb.lottie" />
      </div>
    </div>
  </section>
</template>

<script>
import { DotLottieVue } from '@lottiefiles/dotlottie-vue';

export default {
  components: { DotLottieVue }

}
</script>

<style lang="scss" scoped>
#about {
  background: $purple;

  h2 {
    color: $yellow;
  }

  p {
    color: $grey;
  }

  .lottie {
    width: 35rem;
    height: 30rem;
  }

  @media (max-width: #{$media-medium}) {
    max-width: 100% !important;

    .text {
      width: 100%;
    }

    .image {
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .lottie {
      display: none;
    }
  }
}
</style>