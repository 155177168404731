<template>
  <section id="contact" class="flex items-center justify-center">
    <div class="container flex">
      <div class="flex flex-column items-center gap-12">
        <h2>Shoot me a message!</h2>
        <p>Got a question or proposal, or just want to say Hi? Feel free to write me.</p>

        <form>
          <div class="group">
            <div class="information">
              <label for="full-name">Your Name</label>
              <input id="full-name" placeholder="Enter your name" type="text" v-model="form.name" required="required">
            </div>

            <div class="information">
              <label for="email">Your Email Address</label>
              <input id="email" placeholder="Enter your email address" type="email" v-model="form.email" required="required">
            </div>
          </div>

          <div class="information">
            <label for="message">Your Email Address</label>
            <textarea id="message" placeholder="Hi, we need a design. Can you help us?" rows="7" minlength="30" v-model="form.message" required="required"></textarea>
          </div>
        </form>

        <MButton content="Shoot" v-on:click="sendForm" />
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

import MButton from '@/components/MButton.vue';

export default {
  components: { MButton },
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      }
    }
  },
  methods: {
    async sendForm() {
      if (this.form.name.length <= 0 || this.form.email.length <= 0 || this.form.message.length <= 0) {
        alert('Please fill out all fields');
        return;
      }

      await axios.post('https://api.web3forms.com/submit', {
        access_key: 'c0a08aa1-317e-4ec8-b59e-a89f581619ba',
        name: this.form.name,
        email: this.form.email,
        message: this.form.message,
      }).then((res) => {
        if (res.status === 200) {
          alert('Successfully sent!');

          this.form.name = '';
          this.form.email = '';
          this.form.message = '';
        } else  {
          alert('Something wrong happened! Please try again later!');
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
#contact {
  background: $grey;

  h2 {
    color: $purple;
  }

  p {
    color: $dark-purple;
    margin-bottom: 36px;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 120%;
    gap: 20px;
    margin-bottom: 36px;

    @media (max-width: #{$media-large}) {
      width: 100%;
    }

    & .group {
      display: flex;
      gap: 2rem;

      @media (max-width: #{$media-small}) {
        flex-direction: column;
      }
    }

    .information {
      display: flex;
      flex-direction: column;
      width: 100%;

      label {
        font-size: 1rem;
        color: #9b9b9b;
        transition: .1s ease-in-out;

        @media (max-width: #{$media-medium}) {
          font-size: 1.355rem;
        }
      }

      &:focus-within label {
        color: $purple;
      }

      input, textarea {
        outline: none;
        border: none;
        border-bottom: 1px solid grey;
        padding: 1rem 2rem 1rem 0;
        background: transparent;
        font-size: 1.25rem;
        transition: .1s ease-in-out;
        color: #9b9b9b;
        border-radius: 0;

        @media (max-width: #{$media-medium}) {
          font-size: 1.515rem !important;
        }

        &:focus {
          color: $purple;
          border-bottom: 1px solid $purple;
        }
      }

      textarea {
        width: 100%;
        height: 8rem;
        resize: none;
        overflow-y: scroll;
      }
    }
  }
}
</style>