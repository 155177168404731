<template>
  <section id="footer" class="flex items-center justify-center">
    <div class="container flex flex-column full-width">
      <div class="flex flex-column gap-20">
        <h4>Say Hello</h4>

        <div class="main flex gap-xxxl">
          <ul>
            <li>
              <a href="mailto:hello@marlon-schirl.me">hello@marlon-schirl.me</a>
            </li>
            <li>
              <a href="https://www.mexemo.com/">Mexemo Web Design (DE)</a>
            </li>
          </ul>

          <ul>
            <li>
              <a href="/my-work">My Work</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="bottom flex justify-between items-center gap-12 full-width">
        <p>© Marlon Schirl {{ new Date().getFullYear() }}</p>

        <ul>
          <li>
            <a aria-label="github" rel="noreferrer" target="_blank" href="https://github.com/cptprice1139">
              <img src="@/assets/images/icons/y/github.svg" alt="Github" />
            </a>
          </li>
          <li>
            <a aria-label="linkedin" rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/marlon-schirl-81a046296/">
              <img src="@/assets/images/icons/y/linkedin.svg" alt="LinkedIn" />
            </a>
          </li>
          <li>
            <a aria-label="instagram" rel="noreferrer" target="_blank" href="https://www.instagram.com/marloni._/">
              <img src="@/assets/images/icons/y/instagram.svg" alt="Instagram" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
#footer {
  background: $dark-purple;

  .main {
    padding-bottom: 4rem;

    @media (max-width: #{$media-medium}) {
      gap: 0;
      justify-content: space-between;
    }

    a {
      &::after {
        content: '';
        width: 20%;
        min-width: 1em;
        height: 100%;
        opacity: 0;
        top: 0;
        right: 0;
        transform-origin: left;
        transition: .3s;
        position: absolute;
        pointer-events: none;
        background-color: $grey;
      }

      &:hover::after {
        opacity: .15;
        transform: translateX(20%);
      }
    }
  }

  .bottom {
    padding-top: 4rem;
    font-weight: 400;
    color: $yellow;
    border-top: 1px solid #cbc9e2;

    p {
      color: $yellow;
    }

    ul {
      flex-direction: row;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    li {

      a {
        position: relative;
        font-weight: 400;
        color: $yellow;
        text-decoration: none;
        margin: 0;
        padding: 0;

        img {
          width: 2.5rem;
          height: 2.5rem;
          transition: .2s ease-in-out;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
</style>