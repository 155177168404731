<template>
  <nav>
      <h3 class="logo" v-on:click="redirectToHome" :class="{ active: nameExpanded }">M<span id="arlon">{{ nameExpanded ? 'arlon': ''}}</span>S<span id="chirl">{{ nameExpanded ? 'chirl': ''}}</span></h3>

      <button v-on:click="expanded = !expanded" :class="{ active: expanded }"></button>

      <div class="nav-menu" :class="{ expanded: expanded }">
          <div class="content">
            <ul>
                <li>
                    <a href="/my-work">My Work</a>
                </li>
            </ul>

            <div class="flex flex-column gap-20">
              <h4>Say Hello</h4>

              <ul>
                <li>
                  <a href="mailto:hello@marlon-schirl.me">hello@marlon-schirl.me</a>
                </li>
                <li>
                    <a href="https://www.mexemo.com/">Mexemo Web Design (DE)</a>
                </li>
              </ul>
            </div>

            <ul class="socials">
                <li>
                    <a aria-label="github" rel="noreferrer" target="_blank" href="https://github.com/cptprice1139">
                      <img src="@/assets/images/icons/p/github.svg" alt="Github" /></a>
                </li>
                <li>
                    <a aria-label="linkedin" rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/marlon-schirl-81a046296/">
                      <img src="@/assets/images/icons/p/linkedin.svg" alt="LinkedIn" />
                    </a>
                </li>
                <li>
                    <a aria-label="instagram" rel="noreferrer" target="_blank" href="https://www.instagram.com/marloni._/">
                      <img src="@/assets/images/icons/p/instagram.svg" alt="Instagram" />
                    </a>
                </li>
            </ul>
          </div>
      </div>
  </nav>
</template>

<script>
export default {
    props: {
        nameExpanded: {
            type: Boolean
        }
    },
    data() {
        return {
            expanded: false
        }
    },
    watch: {
        expanded(newVal, oldVal) {
            if (newVal) {
                setTimeout(() => {
                    document.getElementsByClassName('content')[0].classList.add('shown');
                }, 200);
            } else {
                setTimeout(() => {
                    document.getElementsByClassName('content')[0].classList.remove('shown');
                }, 50);
            }
        }
    },
    methods: {
        redirectToHome() {
            window.location.href = '/';
        }
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: #{$media-medium}) {
  nav {
    position: absolute !important;
  }
}


nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.25vw 5vw;
  z-index: 9999;

  h3 {
    color: var(--logoColor);
    font-size: 2.125rem;
    cursor: pointer;
    transition: .5s ease-in-out;
    display: flex;
    margin: 0;

    span {
      font-size: 2.125rem;
      color: var(--logoColor);
      transition: .5s ease-in-out;
    }

    &.active > #arlon {
      width: calc(6.25rem + 3.2px);
    }

    &.active > #chirl {
      width: calc(4.525rem + 4.2px);
    }

    span {
      width: 0;
      overflow: hidden;
      transition: all .4s ease-in-out;
    }
  }

  button {
    position: relative;
    z-index: 9999;
    width: 2.525rem;
    height: 1.775rem;
    background: none;
    color: $purple;
    border: none;

    &::before, &::after {
      content: '';
      height: 2px;
      right: 0;
      position: absolute;
      transform: rotate(0deg);
      background-color: var(--currentColor);
      transition: .2s ease-in-out;
    }

    &::before {
      width: 100%;
      top: 0;
    }

    &::after {
      width: 80%
    }

    &:hover, &.active {
      cursor: pointer;
    }

    &.active {
      color: $purple;

      &::after, &::before {
        background: $purple;
      }

      &::after {
        width: 100%;
        top: 50%;
        transform: rotate(-45deg);
      }

      &::before {
        top: 50%;
        transform: rotate(45deg);
      }
    }
  }

  .nav-menu {
    opacity: 0;
    background: white;
    top: 2vw;
    right: 4vw;
    width: 0;
    height: 0;
    position: absolute;
    z-index: 9998;
    -webkit-transition: opacity 0.4s, box-shadow 0.4s;
    transition: opacity 0.4s, height .4s, width .4s, box-shadow 0.4s, color .5s;
    box-shadow: 0 10px 53px 0 rgba(72, 49, 212, .05);

    @media (max-width: #{$media-small}) {
      top: 0 !important;
      right: 0 !important;

      &.expanded {
        width: 100vw !important;
        height: 100vh !important;
      }

      .content {
        justify-content: flex-start !important;
        padding: 3rem 0 0 3rem !important;
      }
    }

    .content {
      display: none;
      flex-direction: column;
      justify-content: flex-end;
      gap: 48px;
      height: 100%;
      padding: 0 0 3rem 3rem;
      opacity: 0;
      transition: .4s ease-in-out;

      h4 {
        font-size: 1.25rem;
      }

      &.shown {
        opacity: 1;
        display: flex;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;

        li a {
          position: relative;
          text-decoration: none;
          color: $purple;

          @media (max-width: #{$media-small}) {
            font-size: 2rem !important;
          }

          &::after {
            content: '';
            width: 20%;
            min-width: 1em;
            height: 100%;
            opacity: 0;
            top: 0;
            right: 0;
            transform-origin: left;
            transition: .3s;
            position: absolute;
            pointer-events: none;
            background-color: $dark-grey;
          }

          &:hover::after {
            opacity: .15;
            transform: translateX(20%);
          }
        }

        li img {
          width: 2.525rem;
          height: 2.525rem;
          transition: .2s ease-in-out;

          &:hover {
            transform: scale(1.1);
          }

          @media (max-width: #{$media-small}) {
            width: 4rem;
            height: 4rem;
          }
        }

        &.socials {
          flex-direction: row;
          gap: 4px;

          li a:hover::after {
            opacity: 0;
          }
        }
      }
    }

    &.expanded {
      opacity: 1;
      transform: scale(1);
      width: 24rem;
      height: 29rem;
    }
  }
}
</style>