<template>
  <div class="sidebar" v-if="currentSection !== sectionAmount-1">
    <ul>
      <li v-for="(section, i) in sectionAmount" :key="i" :class="{ active: currentSection === i }" v-on:click="sectionCallback(i)" v-show="section !== sectionAmount"></li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    sectionAmount: {
      type: Number,
      required: true
    },
    sectionCallback: {
      type: Function
    }
  },
  data() {
    return {
      currentSection: 0,
      screenWidth: 0
    }
  },
  methods: {
    handleScroll(event) {
      if (event.deltaY > 0) {
        this.nextSection();
      } else {
        this.previousSection();
      }

      window.removeEventListener('wheel', this.handleScroll, false);

      setTimeout(() => {
        window.addEventListener('wheel', this.handleScroll, false);
      }, 250);
    },
    nextSection() {
      if (this.screenWidth < 768) return;

      if (this.currentSection < document.querySelectorAll('section').length - 1) {
        this.currentSection++;

        this.scrollToSection(this.currentSection);
      }
    },
    previousSection() {
      if (this.screenWidth < 786) return;

      if (this.currentSection > 0) {
        this.currentSection--;

        this.scrollToSection(this.currentSection);
      }
    },
    scrollToSection(i) {
      const section = document.querySelectorAll('section')[i];
      section.scrollIntoView({ behavior: 'smooth' });

      this.colorChange(i);

      this.currentSection = i;

      this.$props.sectionCallback(i);
    },
    colorChange(index) {
      const rootStyle = document.querySelector(':root').style;

      if (index === 2) {
        rootStyle.setProperty('--currentColor', '#ccf381');
        rootStyle.setProperty('--logoColor', '#ccf381');
      } else {
        rootStyle.setProperty('--currentColor', '#4831D4FF');

        const isEdgeSection = (index === 0 || index === this.sectionAmount - 1);
        const logoColor = isEdgeSection ? '#ccf381' : '#4831D4FF';

        rootStyle.setProperty('--logoColor', logoColor);

        if (index !== 0 && isEdgeSection) {
          rootStyle.setProperty('--currentColor', '#ccf381');
        }
      }
    },
    handleResize(event) {
      this.screenWidth = event.target.innerWidth;

      if (this.screenWidth > 786) {
        document.querySelector('body').style.overflow = 'hidden';
      } else {
        document.querySelector('body').style.overflow = 'scroll';

        if (this.screenWidth <= 786) {
          document.querySelector(':root').style.setProperty('--currentColor', '#ccf381');
        } else {
          document.querySelector(':root').style.setProperty('--currentColor', '#4831D4FF');
        }
      }
    },
    debounce(func, wait) {
      let timeout;
      return function(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    }
  },
  mounted() {
    this.screenWidth = window.innerWidth;
    window.addEventListener('resize', this.debounce(this.handleResize.bind(this), 100));

    if (this.screenWidth > 786) {
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.overflow = 'scroll';
      document.querySelector(':root').style.setProperty('--currentColor', '#ccf381');
    }

    window.addEventListener('wheel', this.handleScroll);

    const section = document.querySelectorAll('section')[0];
    section.scrollIntoView({ behavior: 'smooth' });
  },
  unmounted() {
    window.removeEventListener('wheel', this.handleScroll);
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  position: fixed;
  right: 5vw;
  bottom: 30%;
  z-index: 2;

  @media (max-width: 768px) {
    display: none;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 1.755vw;

    li {
      display: flex;
      width: inherit;
      cursor: pointer;
      justify-content: center;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: .65vw;
        height: .65vw;
        background: var(--currentColor);
        transform: rotate(45deg);
        transition: .2s ease-in-out;
      }

      &.active::after {
        content: '';
        position: absolute;
        transform: scale(1.2);
        background: transparent;
        border-radius: 50%;
        margin-top: -1px;
        border: 2px solid var(--currentColor);
      }
    }
  }
}
</style>