<template>
  <a class="m-button" :href="$props.href" @click="clickFunction">
      <div class="content">
          {{ $props.content }}
          <svg width="72" height="22" viewBox="0 0 72 22" xmlns="http://www.w3.org/2000/svg" class="bow-arrow"><path fill="none" stroke-width="2" stroke-miterlimit="0" d="M.043 11.119h70.714M60.917 1.319l9.8 9.8-9.8 9.8"></path></svg>
      </div>
  </a>
</template>

<script>
export default {
    props: {
        content: {
            type: String
        },
        href: {
            type: String
        },
        clickFunction: {
            type: Function
        }
    }
}
</script>

<style lang="scss" scoped>
.m-button {
  text-decoration: none;

  & .content {
    border: 2px solid $purple;
    color: $purple;
    padding: .65rem 2rem;
    position: relative;
    cursor: pointer;
    display: inline-block;
    align-items: center;
    box-shadow: inset 0 0 0 0 $purple;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: .4s ease-in-out;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.115rem !important;

    @media (max-width: #{$media-medium}) {
      font-size: 1.525rem !important;
    }

    &:hover {
      color: $white;
      box-shadow: inset 400px 0 0 0 $purple;

      svg {
        stroke: $white;
      }
    }

    svg {
      width: 3rem;
      height: 2rem;
      margin-left: 1vw;
      vertical-align: middle;
      stroke: $purple;
      transition: .6s ease-in-out;
    }
  }
}
</style>