<template>
  <router-view/>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --currentColor: #4831D4FF;
  --logoColor: #ccf381;
  --animate-duration: 1000ms;
  --animate-delay: 1s;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  outline: none;
  font-family: 'Poppins', sans-serif;
}

body,
#app {
  margin: 0;
  padding: 0;
  color: $dark-purple;
  background-color: $grey;
  overflow: hidden;
}

html {
  font-size: clamp(0.65rem, calc(max(0.1628rem + 0.9302vw)), 1rem);
}

::-webkit-scrollbar {
  display: none;
}

h1 {
  font-size: 4rem;
  font-weight: 800;
  margin: 0;

  span {
    color: white;
    font-size: inherit;
    font-weight: inherit;
  }
}

h2 {
  font-size: 3.25rem;
  font-weight: 800;
  margin: 0;

  span {
    color: white;
    font-size: inherit;
    font-weight: inherit;
  }
}

h4 {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 4px;
  color: #cbc9e2;
  text-transform: uppercase;
  margin: 0;
}

p, a, i {
  font-size: 1.25rem;
  color: $grey;
  margin: 0;
}
</style>
