<template>
  <Navbar :name-expanded="currentSection === 0" />

  <Sidebar :section-amount="sectionAmount" :section-callback="sectionCallback" />

  <Hero />

  <HowItWorks />

  <About />

  <MyWork />

  <Contact />

  <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Hero from '@/components/sections/Hero.vue';
import Footer from '@/components/sections/Footer.vue';
import Sidebar from '@/components/Sidebar.vue';
import HowItWorks from '@/components/sections/HowItWorks.vue';
import About from '@/components/sections/About.vue';
import MyWork from '@/components/sections/MyWork.vue';
import Contact from '@/components/sections/Contact.vue';

import { ref } from 'vue';
import { useMeta } from 'vue-meta';

export default {
    components: { Contact, MyWork, About, HowItWorks, Sidebar, Footer, Hero, Navbar },
    data() {
        return {
          currentSection: 0,
          sectionAmount: 6,
        }
    },
    methods: {
        sectionCallback(i) {
          this.currentSection = i
        }
    },
    setup() {
        useMeta({
          link: [
            { rel: 'canonical', href: 'https://marlon-schirl.me/' }
          ]
        });
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: #{$media-small}) {
  body {
    overflow: scroll;
  }
}
</style>