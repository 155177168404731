<template>
  <section id="my-work" class="flex items-center justify-center">
    <div class="container flex flex-row full-width">
      <div class="ca flex flex-column gap-12">
        <h2>I design & build stuff</h2>
        <p>Open source projects and web apps, as well as work I've done in the past.</p>
        <MButton content="See my work" href="/my-work" />
      </div>
    </div>
  </section>
</template>

<script>
import MButton from '@/components/MButton.vue';

export default {
  components: { MButton }

}
</script>

<style lang="scss" scoped>
#my-work {
  background: $grey;

  @media (max-width: #{$media-medium}) {
    .container {
      width: 100%;

      .ca {
        justify-content: center;
        align-items: center;
      }
    }
  }

  h2 {
    color: $purple;
  }

  p {
    color: $dark-purple;
  }
}
</style>